<template>
  <v-container id="user-table" fluid tag="section">
    <delete-dialog ref="deleteConfirm" :url="apiQuery" :title="$t('quiz.delete quiz')"
      :confirmation="$t('quiz.deleted')" @itemDeleted="onDeleted">
      <template v-slot:default="item">{{
      $t("quiz.delete confirm", { name: item.name })
    }}</template>

      <template v-slot:error="error">
        <v-alert v-if="error.message" tile class="mb-0" type="error">{{
      $t(error.message)
    }}</v-alert>
      </template>
    </delete-dialog>
    <base-panel icon="mdi-file-question-outline" :title="$t('quiz.page title')">
      <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="total"
        :loading="loading" :footer-props="{
      'items-per-page-options': [20, 50, 100],
    }">
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="addQuiz">
              <v-icon left>mdi-plus</v-icon>
              {{ $t("quiz.add quiz") }}
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="redirectEdit(item.id)">mdi-pencil</v-icon>
          <v-icon small @click="onDelete(item)" v-if="item.status == 'draft'">mdi-delete</v-icon>
          <v-icon v-if="sendToGroups && item.status == 'published'" small class="mr-2"
            @click="sendMailDialog(item)">mdi-email-send</v-icon>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ $t("quiz.statuses." + item.status) }}
        </template>
      </v-data-table>
    </base-panel>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import DeleteDialog from "@/components/crud/DeleteDialog";

export default {
  mixins: [crudMixin],
  components: {
    DeleteDialog,
  },
  data() {
    return {
      sendToGroups: false,
      apiQuery: "/quiz/",
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: this.$t("Name"), value: "name" },
        { text: this.$t("Status"), value: "status" },
        { text: this.$t("quiz.total subscales"), value: "total_subscales" },
        { text: this.$t("quiz.total questions"), value: "total_questions" },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
    };
  },
  methods: {
    addQuiz() {
      this.$router.push("/quizzes/create");
    },
    redirectEdit(id) {
      this.$router.push("/quizzes/" + id);
    },
    sendMailDialog(item) {
      this.$refs.mailForm.showDialog(item);
    },
  },
};
</script>
